import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const drugGroupList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'druggroup/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('Get drug group list err : ', err)

      return {}
    })

  return response
}

const drugGroupAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'druggroup',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('drug group add err : ', err)

      return {}
    })

  return response
}

const drugGroupUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'druggroup',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('drug group update err : ', err)

      return {}
    })

  return response
}

const drugGroupUpdateStatus = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'druggroup/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('drug group status update err : ', err)

      return {}
    })

  return response
}

export default {
  drugGroupList, drugGroupAdd, drugGroupUpdate, drugGroupUpdateStatus,
}
