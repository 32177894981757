<template>
  <div>
    <v-dialog v-model='isAddNewDetailDrugSet' persistent max-width='500'>
      <v-card>
        <v-card-title>
          {{ $t('addList') }}
        </v-card-title>
        <v-form
          ref='formAddDetailCourseSet'
          @submit.prevent='createDetailDrugSet'
        >
          <v-card-text>
            <!--            <v-autocomplete
                          v-model='druggroup_id'
                          outlined
                          dense
                          :label="$t('Medicine / Equipment Group')"
                          :items='drugGroupList'
                          :no-data-text="$t('no_information')"
                          class='mt-5'
                          hide-details='auto'
                          item-text='druggroup_name'
                          item-value='druggroup_id'
                        ></v-autocomplete>
                        <v-autocomplete
                          v-model='drugtype_id'
                          outlined
                          dense
                          :label="$t('Type Medicine / Equipment')"
                          :items='drugTypeList'
                          :no-data-text="$t('no_information')"
                          class='mt-5'
                          hide-details='auto'
                          item-text='drugtype_name'
                          item-value='drugtype_id'
                        ></v-autocomplete>
                        -->
            <v-select
              v-model='drug_category_id'
              outlined
              dense
              :label="$t('category')"
              :items='catList'
              hide-details='auto'
              class='mt-5'
              :no-data-text="$t('no_information')"
              :item-text='$i18n.locale'
              item-value='value'
            ></v-select>
            <v-autocomplete
              v-model='drug_id_pri'
              outlined
              dense
              :label="$t('list_drug_equipment')"
              :rules='[required]'
              :items='drugList'
              hide-details='auto'
              return-object
              class='mt-5'
              :no-data-text="$t('no_information')"
              item-text='drug_name'
              item-value='drug_id_pri'
            >
              <template v-slot:item='{ item }'>
                <span v-if='item.drug_id' class='me-1'>
                  ({{ item.drug_id }})
                </span>
                <span>
                  {{ item.drug_name }}
                </span>
              </template>
              <template v-slot:selection='{ item }'>
                <span v-if='item.drug_id' class='me-1'>
                  ({{ item.drug_id }})
                </span>
                <span>
                  {{ item.drug_name }}
                </span>
              </template>

            </v-autocomplete>

            <v-text-field
              v-model.trim='drugset_map_price'
              hide-details='auto'
              class='mt-5'
              type='number'
              :label="$t('price')"
              outlined
              :rules='[required]'
              dense
            >
            </v-text-field>

            <v-text-field
              v-model.trim='drugset_map_amount'
              hide-details='auto'
              class='mt-5'
              type='number'
              :label="$t('amount')"
              outlined
              :rules='[required]'
              dense
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              type='submit'
              :loading='loading'
              :disabled='loading'
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color='secondary'
              @click="$emit('update:isAddNewDetailDrugSet',false)"
              outlined
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required, textValidation } from '@/@core/utils/validation'
import drugSet from '@/api/drugSet/drugSet'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewDetailDrugSet',
    event: 'update:isAddNewDetailDrugSet',
  },
  props: {
    isAddNewDetailDrugSet: {
      type: Boolean,
      default: false,
    },
    drugList: {
      type: Array,
      default: () => [],
    },
    drugGroupList: {
      type: Array,
      default: () => [],
    },
    drugTypeList: {
      type: Array,
      default: () => [],
    },
    catList: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  setup(props, { root, emit }) {
    const drugset_map_price = ref('')
    const druggroup_id = ref('')
    const drugtype_id = ref('')
    const drugset_map_amount = ref('')
    const drug_id_pri = ref('')
    const drug_category_id = ref('')
    const formAddDetailCourseSet = ref(false)
    const loading = ref(false)

    const createDetailDrugSet = () => {
      const isFormValid = formAddDetailCourseSet.value.validate()
      if (!isFormValid) return
      loading.value = true
      drugSet.detailDrugSetAdd({
        drugset_id: root.$route.params.id,
        drug_id_pri: drug_id_pri.value.drug_id_pri,
        drugset_map_price: drugset_map_price.value,
        drugset_map_amount: drugset_map_amount.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:isAddNewDetailDrugSet', false)
        emit('onAdd')
        loading.value = false
        clearData()
      }).catch(err => {
        console.log('error : ', err)
        loading.value = false
      })
    }

    const clearData = () => {
      drugset_map_price.value = ''
      drugset_map_amount.value = ''
      drug_id_pri.value = ''
    }

    watch(druggroup_id, value => {
      drug_id_pri.value = ''
      emit('changeCourseGroup', value)
    })

    watch(drugtype_id, value => {
      drug_id_pri.value = ''
      emit('changeCourseType', value)
    })

    watch(drug_category_id, value => {
      drug_id_pri.value = ''
      emit('changeCourseCat', value)
    })

    watch(drug_id_pri, value => {
      drugset_map_price.value = value.drug_price
      drugset_map_amount.value = value.drug_price ? '1' : ''
    })

    watch(() => props.isAddNewDetailDrugSet, value => {
      if (value) {
        clearData()
      }
    })

    return {
      drug_id_pri,
      druggroup_id,
      drugtype_id,
      drug_category_id,
      drugset_map_price,
      loading,
      createDetailDrugSet,
      formAddDetailCourseSet,
      drugset_map_amount,
      required,
      textValidation,
    }
  },

}
</script>
