<template>
  <div>
    <v-card :loading='loading'>
      <v-card-title>
        <router-link
          class='font-weight-medium text-decoration-none me-2'
          :to="{ name: 'Set-Medicine/Equipment'}"
        >
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                color='primary'
                v-bind='attrs'
                icon
                v-on='on'
              >
                <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('back') }}</span>
          </v-tooltip>
        </router-link>
        {{ drugSetResult.drugset_name }}
        <StatusBlock class='ms-2' :status='String(drugSetResult.drugset_active_id)' />
        <v-spacer></v-spacer>
        <v-btn color='primary' @click='isAddNewDetailDrugSet = true'>
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          {{ $t('addDrugAndEquipment') }}
        </v-btn>
      </v-card-title>
      <v-row class='px-5'>
        <v-col
          cols='12'
          md='6'
          lg='4'
        >
          <span class='font-weight-semibold'>{{ $t('code') }} :</span>
          {{ drugSetResult.drugset_id }}
        </v-col>
        <v-col
          cols='12'
          md='6'
          lg='4'
        >
          <span class='font-weight-semibold'> {{ $t('price') }} : </span>
          {{ drugSetResult.drugset_price }}
        </v-col>
      </v-row>
      <v-data-table
        class='mt-3'
        :headers='columns'
        :items='dataTableList'
        dense
        :options.sync='options'
        :loading='loading'
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.drugset_map_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.drugset_map_active_id`]='{ item }'>
          <StatusBlock :status='String(item.drugset_map_active_id)' />
        </template>

        <template v-slot:[`item.drug_name`]='{ item }'>
          {{ item.drug_name }}
        </template>
        <template v-slot:[`item.drugset_map_price`]='{ item }'>
          {{ item.drugset_map_price | formatPrice }}
        </template>
        <template v-slot:[`item.actions`]='{ item }'>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                @click='dataEdit= item;isEditDetailDrugSet = true'
                v-bind='attrs'
                v-on='on'
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            {{ $t('edit') }}
          </v-tooltip>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                @click='dataEdit = item;isUpdateStatus = true'
                v-bind='attrs'
                v-on='on'
              >
                <v-icon>
                  {{ item.drugset_map_active_id == 1 ? icons.mdiClose : icons.mdiCheck
                  }}
                </v-icon>
              </v-btn>
            </template>
            {{ item.drugset_map_active_id == 1 ? $t('suspend') : $t('normal') }}
          </v-tooltip>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                v-bind='attrs'
                v-on='on'
                @click='removeId = item.drugset_map_id;isRemove = true'
              >
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            {{ $t('delete') }}
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewDetailDrugSet v-model='isAddNewDetailDrugSet'
                         :drug-group-list='drugGroupList'
                         :drug-type-list='drugTypeList'
                         :drug-list='dataDrugList'
                         :cat-list='drugCategoryList'
                         @changeCourseGroup='onChangeDrugGroup'
                         @changeCourseType='onChangeDrugType'
                         @changeCourseCat='onChangeDrugCat'
                         @onAdd='getDrugSetById();getDrugSetList();' />

    <RemoveDetailDrugSet v-model='isRemove'
                         @onRemove='getDrugSetById();getDrugSetList();'
                         :remove-id='removeId' />
    <DetailDrugSetUpdateStatus v-model='isUpdateStatus' :data-edit='dataEdit'
                               @onUpdate='getDrugSetById();getDrugSetList();' />

    <EditCourseDetailSet v-model='isEditDetailDrugSet'
                         :dataEdit='dataEdit'
                         :drug-list='dataDrugList'
                         @onUpdate='getDrugSetById();getDrugSetList();'
    />

  </div>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import drugSet from '@/api/drugSet/drugSet'
import {
  mdiPlus,
  mdiPencilOutline,
  mdiCheck,
  mdiDeleteOutline,
  mdiClose,
  mdiArrowLeftBold,
} from '@mdi/js'
import { i18n } from '@/plugins/i18n'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import category_drug_type_for_search from '@/@fake-db/data/category_drug_type_for_search.json'
import drugGroup from '@/api/DrugManagement/drugGroup'
import drugStore from '@/api/DrugManagement/drugStore'
import drugType from '@/api/DrugManagement/drugType'
import AddNewDetailDrugSet from './AddNewDetailDrugSet'
import RemoveDetailDrugSet from './RemoveDetailDrugSet'
import DetailDrugSetUpdateStatus from './DetailDrugSetUpdateStatus.vue'
import EditCourseDetailSet from './EditDrugDetailSet'
import { formatPrice } from '@/plugins/filters'

export default {
  props: {},
  components: {
    EditCourseDetailSet,
    DetailDrugSetUpdateStatus,
    RemoveDetailDrugSet,
    AddNewDetailDrugSet,
    StatusBlock,
  },
  filters: {
    formatPrice,
  },
  setup(_, { root, emit }) {
    const drugSetResult = ref({})
    const isAddNewDetailDrugSet = ref(false)
    const drugGroupList = ref([])
    const drugTypeList = ref([])
    const drugGroupId = ref('')
    const drugTypeId = ref('')
    const options = ref({
      itemsPerPage: -1,
    })
    const footer = ref(i18n.locale === 'en' ? footerDataTablesEN : footerDataTables)
    const search = ref('')
    const dataDrugList = ref([])
    const dataTableList = ref([])
    const isEditDetailDrugSet = ref(false)
    const dataEdit = ref({})
    const columns = ref([
      {
        text: '#',
        align: 'start',
        value: 'drugset_map_id',
        width: 70,
      },
      {
        text: i18n.t('code'),
        value: 'drug_id',
      },
      {
        text: i18n.t('name'),
        value: 'drug_name',
      },
      {
        text: i18n.t('amountUnit'),
        value: 'drug_amount_unit',
        align: 'end',
      },
      {
        text: i18n.t('price'),
        value: 'drugset_map_price',
        align: 'end',
      },
      {
        text: i18n.t('status'),
        value: 'drugset_map_active_id',
        align: 'center',
      },
      {
        text: i18n.t('option'),
        value: 'actions',
        sortable: false,
        align: 'center',
        width: 150,
      },
    ])
    const loading = ref(false)
    const isRemove = ref(false)
    const isUpdateStatus = ref(false)
    const drug_category_id = ref('')
    const drugCategoryList = ref(category_drug_type_for_search.data)
    const removeId = ref('')
    const { drugList } = drugStore
    onMounted(() => {
      getDrugGroup()
      getDrugType()
      getDrugSetList()
      getDrugSetById()
      getDrugList()
    })

    const getDrugGroup = () => {
      drugGroup.drugGroupList({
        searchtext: '',
        druggroup_status_id: '1',
      }).then(res => {
        drugGroupList.value = res.data
        drugGroupList.value.unshift({
          druggroup_id: '',
          druggroup_name: i18n.t('all_categories'),
        })
      })
    }

    const getDrugType = () => {
      drugType.drugTypeList({
        searchtext: '',
        drugtype_status_id: '1',
      }).then(res => {
        drugTypeList.value = res.data
        drugTypeList.value.unshift({
          drugtype_id: '',
          drugtype_name: i18n.t('all_types'),
        })
      })
    }


    const getDrugList = () => {
      drugList({
        searchtext: '',
        drug_status_id: 1,
        drug_category_id: drug_category_id.value,
        druggroup_id: drugGroupId.value,
        drugtype_id: drugTypeId.value,
        active_page: 1,
        per_page: '0',
      }).then(res => {
        dataDrugList.value = res.data
      }).catch(error => {
        console.log('error : ', error)
      })
    }


    const getDrugSetList = () => {
      loading.value = true
      drugSet.detailDrugSetList({
        drugset_id: root.$route.params.id,
      }).then(res => {
        dataTableList.value = res.data
        loading.value = false
      }).catch(err => {
        loading.value = false
        console.log(err)
      })
    }

    const getDrugSetById = () => {
      drugSet.getDrugSetById(root.$route.params.id).then(res => {
        drugSetResult.value = res.data
      }).catch(err => {
        console.log(err)
      })
    }


    const onChangeDrugGroup = (value) => {
      drugGroupId.value = value
      getDrugList()
    }
    const onChangeDrugType = (value) => {
      drugTypeId.value = value
      getDrugList()
    }
    const onChangeDrugCat = (value) => {
      drug_category_id.value = value
      getDrugList()
    }

    return {
      drugSetResult,
      isAddNewDetailDrugSet,
      drugGroupList,
      getDrugSetById,
      dataDrugList,
      columns,
      onChangeDrugGroup,
      onChangeDrugType,
      dataTableList,
      options,
      footer,
      dataEdit,
      getDrugSetList,
      drugTypeList,
      loading,
      drugGroupId,
      isEditDetailDrugSet,
      isRemove,
      removeId,
      onChangeDrugCat,
      isUpdateStatus,
      drugCategoryList,
      icons: {
        mdiPlus,
        mdiPencilOutline, mdiCheck, mdiDeleteOutline,
        mdiArrowLeftBold,
        mdiClose,
      },
    }
  },

}
</script>

<style scoped>

</style>
