import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const drugGet = async id => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'drug',
      param: id,
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.log('Get drug err : ', err)

      return {}
    })

  return response
}

const drugList = async body => {
  const response = await api
    .post({
      path: 'drug/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('drug list get err : ', err)

      return {}
    })

  return response
}

const drugAdd = async (path, body) => {
  const response = await api
    .post({
      path,
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('drug add err : ', err)

      return {}
    })

  return response
}

const drugUpdate = async body => {
  const response = await api
    .put({
      path: 'drug',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('drug update err : ', err)

      return {}
    })

  return response
}

const drugUpdateStatus = async body => {
  const response = await api
    .put({
      path: 'drug/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('drug status update err : ', err)

      return {}
    })

  return response
}
const drugImportFile = async body => {
  const response = await api
    .post({
      path: 'drug/drugimport',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('drug import file err : ', err)

      return {}
    })

  return response
}
const equipmentImportFile = async body => {
  const response = await api
    .post({
      path: 'drug/equipmentimport',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('Equipment import file err : ', err)

      return {}
    })

  return response
}

const drugOrderList = async body => {
  const response = await api
    .post({
      path: 'drug/orderlist',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('get drug order list err : ', err)

      return []
    })

  return response
}

const addDrugToStore = async body => {
  const response = await api
    .post({
      path: 'drug/order',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add drug to store err : ', err)

      return {}
    })

  return response
}

const updateDrugInStore = async body => {
  const response = await api
    .put({
      path: 'drug/order',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('edit drug in store err : ', err)

      return {}
    })

  return response
}

const drugUpdateStatusInStore = async body => {
  const response = await api
    .put({
      path: 'drug/statusorder',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('drug update status in store err : ', err)

      return {}
    })

  return response
}
export default {
  drugGet,
  drugList,
  drugAdd,
  drugUpdate,
  drugUpdateStatus,
  drugImportFile,
  equipmentImportFile,
  drugOrderList,
  addDrugToStore,
  updateDrugInStore,
  drugUpdateStatusInStore,
}
