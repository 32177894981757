<template>
  <div>
    <v-dialog
      v-model='isRemoveDetailDrugSet'
      persistent
      max-width='350'
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color='warning'
            class='me-1'
          >
            {{ icons.mdiAlertOutline }}
          </v-icon>
          {{ $t('confirmDeleteCourseSetList') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color='error'
            :loading='loading'
            :disabled='loading'
            @click='removeDetailDrugSet'
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color='secondary'
            outlined
            @click="$emit('update:isRemoveDetailDrugSet',false)"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import drugSet from '@/api/drugSet/drugSet'
import store from '@/store'
import { mdiAlertOutline } from '@mdi/js'

export default {
  model: {
    prop: 'isRemoveDetailDrugSet',
    event: 'update:isRemoveDetailDrugSet',
  },
  props: {
    isRemoveDetailDrugSet: {
      type: Boolean,
      default: false,
    },
    removeId: {
      type: String,
      default: '',
    },
  },
  components: {},
  setup(props, { root, emit }) {
    const loading = ref(false)

    const removeDetailDrugSet = () => {
      loading.value = true
      drugSet.detailDrugSetRemove(props.removeId).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:isRemoveDetailDrugSet', false)
        emit('onRemove')
      }).catch(error => {
        console.log('error : ', error)
        loading.value = false
      })
    }

    return {
      removeDetailDrugSet,
      loading,
      icons: {
        mdiAlertOutline,
      },
    }
  },

}
</script>

<style scoped>

</style>
