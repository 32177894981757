<template>
  <div>
    <v-dialog v-model='isEditDrugDetailSet' persistent max-width='500'>
      <v-card>
        <v-card-title>
          {{ $t('edit_list') }}
        </v-card-title>
        <v-form
          ref='formEditDrugDetailSet'
          @submit.prevent='updateDetailDrugSet'
        >
          <v-card-text>
            <v-autocomplete
              v-model='drug_id_pri'
              outlined
              dense
              :label="$t('Checklist')"
              :rules='[required]'
              :items='drugList'
              hide-details='auto'
              disabled
              class='mt-5'
              :no-data-text="$t('no_information')"
              item-text='drug_name'
              item-value='drug_id_pri'
            ></v-autocomplete>
            <v-text-field
              v-model.trim='dataEditLocal.drugset_map_price'
              hide-details='auto'
              class='mt-5'
              type='number'
              :label="$t('price')"
              outlined
              :rules='[required]'
              dense
            >
            </v-text-field>
            <v-text-field
              v-model.trim='dataEditLocal.drugset_map_amount'
              hide-details='auto'
              class='mt-5'
              type='number'
              :label="$t('amount')"
              outlined
              :rules='[required]'
              dense
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              type='submit'
              :loading='loading'
              :disabled='loading'
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color='secondary'
              @click="$emit('update:isEditDrugDetailSet',false)"
              outlined
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required, textValidation } from '@/@core/utils/validation'
import drugSet from '@/api/drugSet/drugSet'
import store from '@/store'

export default {
  model: {
    prop: 'isEditDrugDetailSet',
    event: 'update:isEditDrugDetailSet',
  },
  props: {
    isEditDrugDetailSet: {
      type: Boolean,
      default: false,
    },
    drugList: {
      type: Array,
      default: () => [],
    },
    dataEdit: {
      type: Object,
      default:
        () => ({}),
    }
    ,
  },
  components: {}
  ,
  setup(props, { root, emit }) {
    const drugset_map_price = ref('')
    const drugset_map_amount = ref('')
    const drug_id_pri = ref('')
    const formEditDrugDetailSet = ref(false)
    const loading = ref(false)
    const dataEditLocal = ref({})

    const updateDetailDrugSet = () => {
      const isFormValid = formEditDrugDetailSet.value.validate()
      if (!isFormValid) return
      loading.value = true
      drugSet.detailDrugSetUpdate({
        drugset_map_id: dataEditLocal.value.drugset_map_id,
        drugset_map_price: dataEditLocal.value.drugset_map_price,
        drugset_map_amount: dataEditLocal.value.drugset_map_amount,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:isEditDrugDetailSet', false)
        emit('onUpdate')
        loading.value = false
        clearData()
      }).catch(err => {
        console.log('error : ', err)
        loading.value = false
      })
    }

    const clearData = () => {
      drugset_map_price.value = ''
      drugset_map_amount.value = ''
      drug_id_pri.value = ''
    }


    watch(() => props.isEditDrugDetailSet, value => {
      if (value) {
        dataEditLocal.value = JSON.parse(JSON.stringify(props.dataEdit))
        drug_id_pri.value = dataEditLocal.value.drug_id_pri
      }
    })

    return {
      drugset_map_price,
      drugset_map_amount,
      loading,
      updateDetailDrugSet,
      dataEditLocal,
      formEditDrugDetailSet,
      required,
      drug_id_pri,
      textValidation,
    }
  }
  ,

}
</script>
