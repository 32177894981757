import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const drugTypeList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'drugtype/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('Get drug type list err : ', err)

      return {}
    })

  return response
}

const drugTypeAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'drugtype',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('drug type add err : ', err)

      return {}
    })

  return response
}

const drugTypeUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'drugtype',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('drug type add err : ', err)

      return {}
    })

  return response
}

const drugTypeStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'drugtype/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('drug status update err : ', err)

      return {}
    })

  return response
}

export default {
  drugTypeList, drugTypeAdd, drugTypeStatusUpdate, drugTypeUpdate,
}
